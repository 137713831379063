
@import "~bootstrap/scss/bootstrap";
  .toolbar {
    height: 72px;
    margin: -8px;
    display: flex;
    align-items: center;
    //background-color: #FB7D55;
    color: white;
    font-weight: 600;
  }

  .toolbar img {
    margin: -5px -28px
  }

  .toolbar #twitter-logo {
    height: 40px;
    margin: 0 16px;
  }

  .toolbar #twitter-logo:hover {
    opacity: 0.8;
  }